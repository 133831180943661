import '../App.css';
import React from 'react';
// import { connect } from 'react-redux'
// import { isMobile } from 'react-device-detect';
// import { FaHome } from '@react-icons/all-files/fa/FaHome';
import io from 'socket.io-client';
import { updateNumber, getDataByDate, get5DDataByDate, validateToken } from '../api'
import { initialObject, characterList, characterList2 } from '../static'
import Magnum from '../Component/cards/magnum';
import Damacai from '../Component/cards/damacai';
import Toto from '../Component/cards/toto';
import Sg from '../Component/cards/sg';
import Bt4 from '../Component/cards/bt4';
import Gd from '../Component/cards/gd';
import Stc from '../Component/cards/stc';
import Sbh from '../Component/cards/sbh';
import Swk from '../Component/cards/swk';
import { language } from '../language';
import { connect } from 'react-redux'
import { FaArrowCircleLeft } from '@react-icons/all-files/fa/FaArrowCircleLeft';
import { FaArrowCircleRight } from '@react-icons/all-files/fa/FaArrowCircleRight';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MagnumLogo from '../Images/Cards/magnumlogo.png';
import TotoLogo from '../Images/Cards/toto.png';
import DamacaiLogo from '../Images/Cards/damacaimobile.png';
import BT4Logo from '../Images/Cards/bt4DG1mobile.png';
import Sg4Logo from '../Images/Cards/singaporePool.png';
import SarawakLogo from '../Images/Cards/sarawak.png';
import GdLotto from '../Images/Cards/gd.png';
import Sabah88Logo from '../Images/Cards/sabah88.png';
import SansakanLogo from '../Images/Cards/sandakan.png';
import SuccessLogo from '../Images/Success1.gif';
import Moment from 'moment-timezone';
import Header from '../Component/Header';
var timer

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        username: this.props.username,
        UserID: this.props.userID,
        token: this.props.token,
        date: new Date(),
        curTime: Moment(new Date()).format('YYYYMMDD'),
        TodayDate: Moment(new Date()).format('DD/MM/YYYY (ddd)'),
        showMAG: false,
        showDMC: false,
        showTOTO: false,
        showSG: false,
        showBT4: false,
        showSTC: false,
        showSBH: false,
        showSWK: false,
        mag: initialObject,
        magl: initialObject,
        magjg: initialObject,
        dmc: initialObject,
        dmc6: initialObject,
        sg4: initialObject,
        gd: initialObject,
        gd6: initialObject,
        sdk: initialObject,
        sbh: initialObject,
        sbhlotto: initialObject,
        swk: initialObject,
        toto: initialObject,
        toto5: initialObject,
        toto6: initialObject,
        totopow: initialObject,
        totosta: initialObject,
        totosup: initialObject,
        bt4: initialObject,
        bt5: initialObject,
        sbh3: initialObject,
        MessageType: '',
        DisplayErrorMessage: '',
        DisplayError: false,
      }
      this.setPropsState = this.setPropsState.bind(this);
      this.onKeyEnter = this.onKeyEnter.bind(this);
      this.onKeyEnterOriPosition = this.onKeyEnterOriPosition.bind(this);
    }
  
    async componentDidMount() {
      const initialDate = Moment(this.state.date).format('YYYYMMDD')
      const data = await getDataByDate(initialDate)
      const data5D = await get5DDataByDate(initialDate)
      if (data) {
        this.setState({ mag: data.mag || initialObject })
        this.setState({ dmc: data.dmc || initialObject })
        this.setState({ bt4: data.bt4 || initialObject })
        this.setState({ sg4: data.sg4 || initialObject })
        this.setState({ gd: data.gd || initialObject })
        this.setState({ sdk: data.sdk || initialObject })
        this.setState({ sbh: data.sbh || initialObject })
        this.setState({ swk: data.swk || initialObject })
        this.setState({ toto: data.toto || initialObject })
      } else {
        this.setState({ mag: initialObject })
        this.setState({ dmc: initialObject })
        this.setState({ bt4: initialObject })
        this.setState({ sg4: initialObject })
        this.setState({ gd: initialObject })
        this.setState({ sdk: initialObject })
        this.setState({ sbh: initialObject })
        this.setState({ swk: initialObject })
        this.setState({ toto: initialObject })
      }
      if (data5D) {
        this.setState({ magl: data5D.magl || initialObject })
        this.setState({ magjg: data5D.magjg || initialObject })
        this.setState({ dmc6: data5D.dmc6 || initialObject})
        this.setState({ bt5: data5D.bt5 || initialObject })
        this.setState({ gd6: data5D.gd6 || initialObject })
        this.setState({ sbh3: data5D.sbh3 || initialObject })
        this.setState({ sbhlotto: data5D.sbhlotto || initialObject })
        this.setState({ toto5: data5D.toto5 || initialObject })
        this.setState({ toto6: data5D.toto6 || initialObject })
        this.setState({ totopow: data5D.totopow || initialObject })
        this.setState({ totosta: data5D.totosta || initialObject })
        this.setState({ totosup: data5D.totosup || initialObject })
      } else {
        this.setState({ magjg: initialObject })
        this.setState({ magl: initialObject })
        this.setState({ dmc6: initialObject })
        this.setState({ bt5: initialObject })
        this.setState({ gd6: initialObject })
        this.setState({ sbh3: initialObject })
        this.setState({ sbhlotto: initialObject })
        this.setState({ toto5: initialObject })
        this.setState({ toto6: initialObject })
        this.setState({ totopow: initialObject })
        this.setState({ totosta: initialObject })
        this.setState({ totosup: initialObject })
      }
      // const socket = io(process.env.REACT_APP_WS_SERVER)
      const socket = io('wss://server.ace4dv2.live')
      // const socket = io('http://localhost:8003/')
      socket.on('connect', () => {
        socket.emit('join', 'resultListener')
        socket.on('resultListener', (data) => {
          if (this.state.curTime >= Moment(this.state.date).startOf('day').format('YYYYMMDD') && data && data[this.state.curTime]) {
            this.setState({ mag: data[this.state.curTime].mag || initialObject })
            this.setState({ dmc: data[this.state.curTime].dmc || initialObject })
            this.setState({ bt4: data[this.state.curTime].bt4 || initialObject })
            this.setState({ sg4: data[this.state.curTime].sg4 || initialObject })
            this.setState({ gd: data[this.state.curTime].gd || initialObject })
            this.setState({ sdk: data[this.state.curTime].sdk || initialObject })
            this.setState({ sbh: data[this.state.curTime].sbh || initialObject })
            this.setState({ swk: data[this.state.curTime].swk || initialObject })
            this.setState({ toto: data[this.state.curTime].toto || initialObject })
          }
        })
        socket.emit('join', 'result5DListener')
        socket.on('result5DListener', (data) => {
          if (this.state.curTime >= Moment(this.state.date).startOf('day').format('YYYYMMDD') && data && data[this.state.curTime]) {
            this.setState({ magl: data[this.state.curTime].magl || initialObject })
            this.setState({ magjg: data[this.state.curTime].magjg || initialObject })
            this.setState({ dmc6: data[this.state.curTime].dmc6 || initialObject })
            this.setState({ bt5: data[this.state.curTime].bt5 || initialObject })
            this.setState({ gd6: data[this.state.curTime].gd6 || initialObject })
            this.setState({ sbh3: data[this.state.curTime].sbh3 || initialObject })
            this.setState({ sbhlotto: data[this.state.curTime].sbhlotto || initialObject })
            this.setState({ toto5: data[this.state.curTime].toto5 || initialObject })
            this.setState({ toto6: data[this.state.curTime].toto6 || initialObject })
            this.setState({ totopow: data[this.state.curTime].totopow || initialObject })
            this.setState({ totosta: data[this.state.curTime].totosta || initialObject })
            this.setState({ totosup: data[this.state.curTime].totosup || initialObject })
          }
        })
      })
    }
  

    async changeYesterday() {
      const yesterday = Moment(this.state.date).subtract(1, 'days').toDate()
      this.setState({ date: yesterday })
      this.setSelectedDate(yesterday)
    }
  
    async changeTomorrow() {
      const yesterday = Moment(this.state.date).add(1, 'days').toDate()
      this.setState({ date: yesterday })
      this.setSelectedDate(yesterday)
    }

    async setSelectedDate(date) {
      this.setState({ date })
      const dateDB = Moment(date).format('YYYYMMDD')
      const data = await getDataByDate(dateDB)
      const Bt4Data = data.bt4 || ''
      const data5D = await get5DDataByDate(dateDB)
      if (data) {
        this.setState({ mag: data.mag || initialObject })
        this.setState({ dmc: data.dmc || initialObject })
        this.setState({ bt4: (Object.values(Bt4Data).length > 1) ? data.bt4 : initialObject })
        this.setState({ sg4: data.sg4 || initialObject })
        this.setState({ gd: data.gd || initialObject })
        this.setState({ sdk: data.sdk || initialObject })
        this.setState({ sbh: data.sbh || initialObject })
        this.setState({ swk: data.swk || initialObject })
        this.setState({ toto: data.toto || initialObject })
      } else {
        this.setState({ mag: initialObject })
        this.setState({ dmc: initialObject })
        this.setState({ bt4: initialObject })
        this.setState({ sg4: initialObject })
        this.setState({ gd: initialObject })
        this.setState({ sdk: initialObject })
        this.setState({ sbh: initialObject })
        this.setState({ swk: initialObject })
        this.setState({ toto: initialObject })
      }
      if (data5D) {
        this.setState({ magl: data5D.magl || initialObject })
        this.setState({ magjg: data5D.magjg || initialObject })
        this.setState({ dmc6: data5D.dmc6 || initialObject })
        this.setState({ bt5: data5D.bt5 || initialObject })
        this.setState({ gd6: data5D.gd6 || initialObject })
        this.setState({ sbh3: data5D.sbh3 || initialObject })
        this.setState({ sbhlotto: data5D.sbhlotto || initialObject })
        this.setState({ toto5: data5D.toto5 || initialObject })
        this.setState({ toto6: data5D.toto6 || initialObject })
        this.setState({ totopow: data5D.totopow || initialObject })
        this.setState({ totosta: data5D.totosta || initialObject })
        this.setState({ totosup: data5D.totosup || initialObject })
      } else {
        this.setState({ magjg: initialObject })
        this.setState({ magl: initialObject })
        this.setState({ dmc6: initialObject })
        this.setState({ bt5: initialObject })
        this.setState({ gd6: initialObject })
        this.setState({ sbh3: initialObject })
        this.setState({ sbhlotto: initialObject })
        this.setState({ toto5: initialObject })
        this.setState({ toto6: initialObject })
        this.setState({ totopow: initialObject })
        this.setState({ totosta: initialObject })
        this.setState({ totosup: initialObject })
      }
    }

    toggleResult(platform) {
      this.setState({
        [`show${platform}`]: !this.state[`show${platform}`],
      })
    }

    setPropsState(obj) {
      this.setState(obj)
    }

    async onKeyEnter(e, platform, position) {
      if (e.charCode === 13) {
        // this.DisplayMEssage('Updated', 'Success')
        const response = await updateNumber({
          date: Moment(this.state.date).format('YYYYMMDD'),
          position,
          platform,
          val: e.target.value,
        }, this.props.token)
        if (response.err && response.err === 'Token Invalid or Expired') {
          // this.openNotification('Token Expired')
          timer = setTimeout(() => {
            localStorage.removeItem('token');
            window.location.reload();
          }, 3000);
        } else {
          // this.openNotification('Updated')
          this.DisplayMEssage('Updated Successful', 'Success')
        }
      }
    }

    async onKeyEnterOriPosition(e, platform, position) {
      if (e.charCode === 13) {
        const positionInPlace = characterList2[e.target.value]
        const valueInPlace = this.state[platform][positionInPlace]
        const positionP = position === 'P1OriPosition' ? 'P1' : position === 'P2OriPosition' ? 'P2' : 'P3'
        this.setState({ [platform]: { ...this.state[platform], [positionP]: valueInPlace} })
        this.setState({ [platform]: { ...this.state[platform], [positionInPlace]: ''} })
        this.setState({ [platform]: { ...this.state[platform], [position]: positionInPlace} })
        await updateNumber({
          date: Moment(this.state.date).format('YYYYMMDD'),
          position: positionP,
          platform,
          val: valueInPlace,
        }, this.props.token)
        await updateNumber({
          date: Moment(this.state.date).format('YYYYMMDD'),
          position: positionInPlace,
          platform,
          val: '',
        }, this.props.token)
        const response = await updateNumber({
          date: Moment(this.state.date).format('YYYYMMDD'),
          position,
          platform,
          val: positionInPlace,
        }, this.props.token)
        if (response.err && response.err === 'Token Invalid or Expired') {
          // this.openNotification('Token Expired')
          timer = setTimeout(() => {
            localStorage.removeItem('token');
            window.location.reload();
          }, 3000);
        } else {
          this.DisplayMEssage('Updated Successful', 'Success')
        }
      }
    }

    DisplayMEssage(Message, type) {
      this.setState({ DisplayErrorMessage: Message, DisplayError: true, MessageType: type })
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.setState({ 
          DisplayError: false,
          DisplayErrorMessage: '',
          MessageType: '',
        });
      }, 5000);
    }

    async selectEnter(val, platform, position) {
      const response = await updateNumber({
        date: Moment(this.state.date).format('YYYYMMDD'),
        position,
        platform,
        val,
      })
      if (response.err && response.err === 'Token Invalid or Expired') {
        // this.openNotification('Token Expired')
        timer = setTimeout(() => {
          localStorage.removeItem('token');
          window.location.reload();
        }, 3000);
      } else {
        this.DisplayMEssage('Updated Successful', 'Success')
      }
    }
  
    render() {
      // const currentDateFormat = Moment(this.state.date).format('YYYYMMDD')
      const numberOfCurrentDay = Number(Moment(this.state.date).tz('Asia/Kuala_Lumpur').format('d'))
      const yesterdayDay = numberOfCurrentDay - 1
      const tomorrowDay = numberOfCurrentDay + 1
      return (
        <div style={{ width: '100%', overflowX: 'auto', height: '100vh', flexDirection: 'column', paddingLeft: 20, paddingRight: 20 }}>
        <Header />
        <div className="dcc mobileHeader" style={{ paddingBottom: 20, justifyContent: 'space-between', marginLeft: '4%', flexDirection: 'row' }}>
          <div style={{ fontWeight: 'bold' }}>日期: {this.state.TodayDate}</div>
          {this.state.DisplayError === true && 
          <div className='dcc' style={{ marginBottom: 0, display: 'flex', flexDirection: 'row' }}>
            <img src={SuccessLogo} alt="Logo" style={{ width: 60, marginRight: 5 }}/>
           <div style={{ fontWeight: 'bold', fontSize: '20px', color: (this.state.MessageType === 'Error') ? 'red' : '#77B43F'}}>{` ${this.state.DisplayErrorMessage}`}</div>
          </div>}
          <div className="dcc" style={{ marginRight: 60 }}>
            <button className="dcc datePickerButton button2" onClick={() => this.changeYesterday()}>
              <FaArrowCircleLeft size="16px" color="#000" />
              <div className="mobiledatefont" style={{ marginLeft: 10 }}>
                {language[this.props.currentLanguage].daysOfWeek[yesterdayDay === -1 ? 6 : yesterdayDay]}
              </div>
            </button>
            <DatePicker
              onChange={(date) => this.setSelectedDate(date)}
              dateFormat='dd-MMM-yyyy'
              placeholder="Date"
              maxDate={new Date()}
              selected={this.state.date}
              // popperPlacement="top-right"
              // withPortal
            />
            <button className="dcc datePickerButton button2" onClick={() => this.changeTomorrow()}>
              <div className="mobiledatefont" style={{ marginRight: 10 }}>
                {language[this.props.currentLanguage].daysOfWeek[tomorrowDay === 7 ? 0 : tomorrowDay]}
              </div>
              <FaArrowCircleRight size="16px" color="#000" />
            </button>
          </div>
        </div>
        <div className={`d-flex justify-content-center px-3`}>
          <div className="dcc" style={{ justifyContent: 'center' }} align="left">
            <img src={MagnumLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showMAG && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('MAG')} />
            <img src={DamacaiLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showDMC && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('DMC')} />
            <img src={TotoLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showTOTO && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('TOTO')} />
            <img src={Sg4Logo} alt="Logo" className={`mainPageToggleLogo ${this.state.showSG && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('SG')} />
            <img src={BT4Logo} alt="Logo" className={`mainPageToggleLogo ${this.state.showBT4 && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('BT4')} />
            <img src={GdLotto} alt="Logo" className={`mainPageToggleLogo ${this.state.showGD && 'activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('GD')}/>
            <img src={SansakanLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showSTC && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('STC')} />
            <img src={Sabah88Logo} alt="Logo" className={`mainPageToggleLogo ${this.state.showSBH && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('SBH')} />
            <img src={SarawakLogo} alt="Logo" className={`mainPageToggleLogo ${this.state.showSWK && 'button2 activeResult'}`} style={{ marginRight: 15 }} onClick={() => this.toggleResult('SWK')} />
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
          { this.state.showMAG && 
            <div style={{ flex: "1 1 30%", border: "1px solid black", padding: 10}}>
              <Magnum mag={this.state.mag} magjg={this.state.magjg} magl={this.state.magl} onKeyEnter={this.onKeyEnter} setPropsState={this.setPropsState} onKeyEnterOriPosition={this.onKeyEnterOriPosition}></Magnum>
            </div>
          }
          { this.state.showDMC && 
            <div style={{ flex: "1 1 30%", border: "1px solid black", padding: 10 }}>
              <Damacai dmc={this.state.dmc} dmc6={this.state.dmc6} selectEnter={this.selectEnter} onKeyEnter={this.onKeyEnter} setPropsState={this.setPropsState}></Damacai>
            </div>
          }
          { this.state.showTOTO && 
            <div style={{ flex: "1 1 30%", border: "1px solid black", padding: 10 }}>
              <Toto toto={this.state.toto} toto5={this.state.toto5} toto6={this.state.toto6} totopow={this.state.totopow} totosta={this.state.totosta} totosup={this.state.totosup} selectEnter={this.selectEnter} onKeyEnter={this.onKeyEnter} setPropsState={this.setPropsState}onKeyEnterOriPosition={this.onKeyEnterOriPosition}></Toto>
            </div>
          }
          {this.state.showSG && 
            <div style={{ flex: "1 1 30%", border: "1px solid black", padding: 10 }}>
              <Sg sg4={this.state.sg4} onKeyEnter={this.onKeyEnter} setPropsState={this.setPropsState}></Sg>
            </div>
          }
          {this.state.showBT4 && 
            <div style={{ flex: "1 1 30%", border: "1px solid black", padding: 10 }}>
              <Bt4 bt4={this.state.bt4} bt5={this.state.bt5} onKeyEnter={this.onKeyEnter} setPropsState={this.setPropsState} onKeyEnterOriPosition={this.onKeyEnterOriPosition}></Bt4>
            </div>
          }
          { this.state.showGD && 
            <div style={{ flex: "1 1 30%", border: "1px solid black", padding: 10 }}>
              <Gd gd={this.state.gd} gd6={this.state.gd6} onKeyEnter={this.onKeyEnter} setPropsState={this.setPropsState} onKeyEnterOriPosition={this.onKeyEnterOriPosition}></Gd>
            </div>
          }
          { this.state.showSTC && 
            <div style={{ flex: "1 1 30%", border: "1px solid black", padding: 10 }}>
              <Stc sdk={this.state.sdk} onKeyEnter={this.onKeyEnter} setPropsState={this.setPropsState} onKeyEnterOriPosition={this.onKeyEnterOriPosition}></Stc>
            </div>
          }
          { this.state.showSBH && 
            <div style={{ flex: "1 1 30%", border: "1px solid black", padding: 10 }}>
              <Sbh sbh={this.state.sbh} sbh3={this.state.sbh3} sbhlotto={this.state.sbhlotto} onKeyEnter={this.onKeyEnter} setPropsState={this.setPropsState} onKeyEnterOriPosition={this.onKeyEnterOriPosition}></Sbh>
            </div>
          }
          { this.state.showSWK && 
            <div style={{ flex: "1 1 30%", border: "1px solid black", padding: 10 }}>
              <Swk swk={this.state.swk} onKeyEnter={this.onKeyEnter} setPropsState={this.setPropsState}></Swk>
            </div>
          }
        </div>
      </div>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      // username: state.username,
      token: state.token,
      // Role: state.Role,
      currentLanguage: state.currentLanguage,
    };
  }
  
  export default connect(mapStateToProps, null)(Home);
  