import React, {Component, Fragment} from 'react';
import '../../App.css';
import { characterList } from '../../static'

export default class bt4num extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
<Fragment>
  <div>
  <div className="Boomtowntittle">Boom & Town 4D</div> 
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.bt4.drawNo || ''} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, drawNo: e.target.value} })}  className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', 'drawNo')}/>
    </div>
    <div style={{ display: 'flex',  color: 'white' }}>
      <div className="border"style={{ width: 'calc(100% / 3)',  fontWeight: 'bold' }}>1st Prize 首獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>2nd Prize 二獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>3rd Prize 三獎 </div>
    </div>
    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
      <div  style={{ width: 'calc(100% / 3)',  fontWeight: 'bold', display: 'flex' }}>
        <input value={this.props.bt4.P1} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, P1: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', 'P1')}/>
        <input value={characterList[this.props.bt4.P1OriPosition] || ''} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, P1OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'bt4', 'P1OriPosition')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>
        <input value={this.props.bt4.P2} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, P2: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', 'P2')}/>
        <input value={characterList[this.props.bt4.P2OriPosition] || ''} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, P2OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'bt4', 'P2OriPosition')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>
        <input value={this.props.bt4.P3} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, P3: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', 'P3')}/>
        <input value={characterList[this.props.bt4.P3OriPosition] || ''} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, P3OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'bt4', 'P3OriPosition')}/>
      </div>
    </div>
    <div className="border"style={{  fontWeight: 'bold',marginBottom:10 }}>Special 特別獎</div>
    <div style={{ display: 'flex',marginTop:3,  color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>A</span>
        <input value={this.props.bt4['1']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 1: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '1')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>B</span>
        <input value={this.props.bt4['2']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 2: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '2')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>C</span>
        <input value={this.props.bt4['3']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 3: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '3')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>D</span>
        <input value={this.props.bt4['4']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 4: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '4')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>E</span>
        <input value={this.props.bt4['5']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 5: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '5')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>F</span>
        <input value={this.props.bt4['6']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 6: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '6')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>G</span>
        <input value={this.props.bt4['7']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 7: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '7')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>H</span>
        <input value={this.props.bt4['8']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 8: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '8')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>I</span>
        <input value={this.props.bt4['9']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 9: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '9')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>J</span>
        <input value={this.props.bt4['10']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 10: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '10')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3,marginBottom:10, color: 'white' }}>
      <div className="" style={{width:'calc(100%/5)' , fontWeight:"bold"}}></div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>K</span>
        <input value={this.props.bt4['11']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 11: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '11')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>L</span>
        <input value={this.props.bt4['12']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 12: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '12')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>M</span>
        <input value={this.props.bt4['13']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 13: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '13')}/>
      </div>
      <div className="" style={{width:'calc(100%/5)' , fontWeight:"bold"}}></div>
    </div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10}}>
                        Consolation 安慰獎
                        </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>N</span>
        <input value={this.props.bt4['14']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 14: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '14')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>O</span>
        <input value={this.props.bt4['15']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 15: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '15')}/>
      </div>
      <div   style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>P</span>
        <input value={this.props.bt4['16']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 16: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '16')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>Q</span>
        <input value={this.props.bt4['17']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 17: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '17')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>R</span>
        <input value={this.props.bt4['18']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 18: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '18')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>S</span>
        <input value={this.props.bt4['19']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 19: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '19')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>T</span>
        <input value={this.props.bt4['20']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 20: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '20')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>U</span>
        <input value={this.props.bt4['21']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 21: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '21')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>V</span>
        <input value={this.props.bt4['22']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 22: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '22')}/>
      </div>
      <div style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>W</span>
        <input value={this.props.bt4['23']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, 23: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', '23')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div className="border" style={{width:'calc(100%/2)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>4D Jackpot 1 Prize</div>
      <div className="border" style={{width:'calc(100%/2)' , fontWeight:"bold"}}>4D Jackpot 2 Prize</div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  className="border3" style={{width:'calc(100%/2)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div>  
        <input value={this.props.bt4.jackpot1} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, jackpot1: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', 'jackpot1')}/>
      </div>
      <div  className="border3" style={{width:'calc(100%/2)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div> 
        <input value={this.props.bt4.jackpot2} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props.bt4, jackpot2: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', 'jackpot2')}/>
      </div>
    </div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10}}>Number of winner ( golden )</div>
    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
      <div  style={{ width: 'calc(100%)',  fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
        <input value={this.props.bt4['golden']} onChange={(e) => this.props.setPropsState({ bt4: { ...this.props['golden'], P1: e.target.value} })} className="resulttop" style={{width:'50%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt4', 'golden')}/>
      </div>
    </div>
    <div className="Boomtowntittle">Boom & Town 5D</div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.bt5.drawNo} onChange={(e) => this.props.setPropsState({ bt5: { ...this.props.bt5, drawNo: e.target.value} })}  className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt5', 'drawNo')}/>
    </div>
    <div style={{ display: 'flex',  color: 'white' }}>
      <div className="border"style={{ width: 'calc(100% / 3)',  fontWeight: 'bold' }}>1st Prize 首獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>2nd Prize 二獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>3rd Prize 三獎 </div>
    </div>
    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
      <div  style={{ width: 'calc(100% / 3)',  fontWeight: 'bold', display: 'flex' }}>
        <input value={this.props.bt5['5D1']} onChange={(e) => this.props.setPropsState({ bt5: { ...this.props['5D1'], P1: e.target.value} })} className="resulttop" style={{width:'100%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt5', '5D1')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>
        <input value={this.props.bt5['5D2']} onChange={(e) => this.props.setPropsState({ bt5: { ...this.props['5D2'], P2: e.target.value} })} className="resulttop" style={{width:'100%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt5', '5D2')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>
        <input value={this.props.bt5['5D3']} onChange={(e) => this.props.setPropsState({ bt5: { ...this.props['5D3'], P3: e.target.value} })} className="resulttop" style={{width:'100%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt5', '5D3')}/>
      </div>
    </div>
    <div className="Boomtowntittle">Boom & Town 6D</div>
    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
      <div  style={{ width: 'calc(100%)',  fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
        <input value={this.props.bt5['6D1']} onChange={(e) => this.props.setPropsState({ bt5: { ...this.props['6D1'], P1: e.target.value} })} className="resulttop" style={{width:'50%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt5', '6D1')}/>
      </div>
    </div>
    <div className="Boomtowntittle">Boom & Town 6D + 1</div>
    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
      <div  style={{ width: 'calc(100%)',  fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
        <input value={this.props.bt5['7D1']} onChange={(e) => this.props.setPropsState({ bt5: { ...this.props['7D1'], P1: e.target.value} })} className="resulttop" style={{width:'50%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'bt5', '7D1')}/>
      </div>
    </div>
  </div>
</Fragment>    
        )
    }
}
