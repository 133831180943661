import logo from './logo.svg';
import './App.css';
import Home from './Page/Home';
// import SideBar from './Component/Sidebar';
import Reducers from './reducers/reducer';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import Login from './Page/Login';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const middlewares = applyMiddleware(thunk);
  const store = createStore(
    Reducers, {
      token: localStorage.getItem('token') || '',
      currentLanguage: localStorage.getItem('currentLanguage') || 'cn',
    },
    middlewares,
  )

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <div className="App" style={{ display: 'flex', height: '100vh'}}>
            {/* <SideBar/> */}
            <Route exact path="/Home" component={Home} />
          </div>
        </Switch>
      </Router>
   </Provider>
  );
}

export default App;
