import React, {Component, Fragment} from 'react';
import '../../App.css';
import { characterList } from '../../static'

export default class sbhnum extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
<Fragment>
  <div>
  <div className="Sabahtittle">Sabah 88 4D 沙巴萬字</div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.sbh.drawNo} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, drawNo: e.target.value} })}  className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', 'drawNo')}/>
    </div>
    <div style={{ display: 'flex',  color: 'white' }}>
      <div className="border"style={{ width: 'calc(100% / 3)',  fontWeight: 'bold' }}>1st Prize 首獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>2nd Prize 二獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>3rd Prize 三獎 </div>
    </div>
    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
      <div  style={{ width: 'calc(100% / 3)',  fontWeight: 'bold', display: 'flex' }}>
        <input value={this.props.sbh.P1} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, P1: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', 'P1')}/>
        <input value={characterList[this.props.sbh.P1OriPosition] || ''} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, P1OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'sbh', 'P1OriPosition')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>
        <input value={this.props.sbh.P2} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, P2: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', 'P2')}/>
        <input value={characterList[this.props.sbh.P2OriPosition] || ''} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, P2OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'sbh', 'P2OriPosition')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>
        <input value={this.props.sbh.P3} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, P3: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', 'P3')}/>
        <input value={characterList[this.props.sbh.P3OriPosition] || ''} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, P3OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'sbh', 'P3OriPosition')}/>
      </div>
    </div>
    <div className="border"style={{  fontWeight: 'bold',marginBottom:10 }}>Special 特別獎</div>
    <div style={{ display: 'flex',marginTop:3,  color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>A</span>
        <input value={this.props.sbh['1']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 1: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '1')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>B</span>
        <input value={this.props.sbh['2']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 2: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '2')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>C</span>
        <input value={this.props.sbh['3']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 3: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '3')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>D</span>
        <input value={this.props.sbh['4']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 4: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '4')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>E</span>
        <input value={this.props.sbh['5']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 5: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '5')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>F</span>
        <input value={this.props.sbh['6']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 6: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '6')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>G</span>
        <input value={this.props.sbh['7']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 7: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '7')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>H</span>
        <input value={this.props.sbh['8']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 8: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '8')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>I</span>
        <input value={this.props.sbh['9']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 9: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '9')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>J</span>
        <input value={this.props.sbh['10']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 10: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '10')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3,marginBottom:10, color: 'white' }}>
      <div className="" style={{width:'calc(100%/5)' , fontWeight:"bold"}}></div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>K</span>
        <input value={this.props.sbh['11']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 11: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '11')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>L</span>
        <input value={this.props.sbh['12']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 12: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '12')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>M</span>
        <input value={this.props.sbh['13']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 13: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '13')}/>
      </div>
      <div className="" style={{width:'calc(100%/5)' , fontWeight:"bold"}}></div>
    </div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10}}>
                        Consolation 安慰獎
                        </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>N</span>
        <input value={this.props.sbh['14']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 14: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '14')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>O</span>
        <input value={this.props.sbh['15']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 15: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '15')}/>
      </div>
      <div   style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>P</span>
        <input value={this.props.sbh['16']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 16: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '16')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>Q</span>
        <input value={this.props.sbh['17']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 17: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '17')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>R</span>
        <input value={this.props.sbh['18']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 18: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '18')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>S</span>
        <input value={this.props.sbh['19']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 19: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '19')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>T</span>
        <input value={this.props.sbh['20']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 20: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '20')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>U</span>
        <input value={this.props.sbh['21']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 21: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '21')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>V</span>
        <input value={this.props.sbh['22']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 22: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '22')}/>
      </div>
      <div style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>W</span>
        <input value={this.props.sbh['23']} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, 23: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', '23')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div className="border" style={{width:'calc(100%/2)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>4D Jackpot 1 Prize</div>
      <div className="border" style={{width:'calc(100%/2)' , fontWeight:"bold"}}>4D Jackpot 2 Prize</div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  className="border3" style={{width:'calc(100%/2)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div>  
        <input value={this.props.sbh.jackpot1} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, jackpot1: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', 'jackpot1')}/>
      </div>
      <div  className="border3" style={{width:'calc(100%/2)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div> 
        <input value={this.props.sbh.jackpot2} onChange={(e) => this.props.setPropsState({ sbh: { ...this.props.sbh, jackpot2: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh', 'jackpot2')}/>
      </div>
    </div>
    <div className="Sabahtittle">3D</div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.sbh3.drawNo} onChange={(e) => this.props.setPropsState({ sbh3: { ...this.props.sbh3, drawNo: e.target.value} })}  className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh3', 'drawNo')}/>
    </div>
    <div style={{ display: 'flex',  color: 'white' }}>
      <div className="border"style={{ width: 'calc(100% / 3)',  fontWeight: 'bold' }}>1st Prize 首獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>2nd Prize 二獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>3rd Prize 三獎 </div>
    </div>
    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
      <div  style={{ width: 'calc(100% / 3)',  fontWeight: 'bold', display: 'flex' }}>
        <input value={this.props.sbh3['1']} onChange={(e) => this.props.setPropsState({ sbh3: { ...this.props.sbh3, ['1']: e.target.value} })} className="resulttop" style={{width:'100%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh3', '1')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>
        <input value={this.props.sbh3['2']} onChange={(e) => this.props.setPropsState({ sbh3: { ...this.props.sbh3, ['2']: e.target.value} })} className="resulttop" style={{width:'100%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh3', '2')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>
        <input value={this.props.sbh3['3']} onChange={(e) => this.props.setPropsState({ sbh3: { ...this.props.sbh3, ['3']: e.target.value} })} className="resulttop" style={{width:'100%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbh3', '3')}/>
      </div>
    </div>
    <div className="Sabahtittle" style={{marginTop:10 , marginBottom:10}}>Lotto</div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.sbhlotto.drawNo} onChange={(e) => this.props.setPropsState({ sbhlotto: { ...this.props.sbhlotto, drawNo: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbhlotto', 'drawNo')}/>
    </div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Winning Number</div>
    <div style={{ display: 'flex', marginTop:3,color: 'white' }}>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.sbhlotto['1']} onChange={(e) => this.props.setPropsState({ sbhlotto: { ...this.props.sbhlotto, 1: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbhlotto', '1')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.sbhlotto['2']} onChange={(e) => this.props.setPropsState({ sbhlotto: { ...this.props.sbhlotto, 2: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbhlotto', '2')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.sbhlotto['3']} onChange={(e) => this.props.setPropsState({ sbhlotto: { ...this.props.sbhlotto, 3: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbhlotto', '3')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.sbhlotto['4']} onChange={(e) => this.props.setPropsState({ sbhlotto: { ...this.props.sbhlotto, 4: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbhlotto', '4')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.sbhlotto['5']} onChange={(e) => this.props.setPropsState({ sbhlotto: { ...this.props.sbhlotto, 5: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbhlotto', '5')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.sbhlotto['6']} onChange={(e) => this.props.setPropsState({ sbhlotto: { ...this.props.sbhlotto, 6: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbhlotto', '6')}/>
      </div>
      <div className="plusbtn" style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>+</div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.sbhlotto['8']} onChange={(e) => this.props.setPropsState({ sbhlotto: { ...this.props.sbhlotto, 8: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbhlotto', '8')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div className="border" style={{width:'calc(100%/2)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>Jackpot 1 Prize</div>
      <div className="border" style={{width:'calc(100%/2)' , fontWeight:"bold"}}>Jackpot 2 Prize</div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white', marginBottom: 20 }}>
      <div  className="border3" style={{width:'calc(100%/2)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div>  
        <input value={this.props.sbhlotto.jackpot1} onChange={(e) => this.props.setPropsState({ sbhlotto: { ...this.props.sbhlotto, jackpot1: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbhlotto', 'jackpot1')}/>
      </div>
      <div  className="border3" style={{width:'calc(100%/2)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div> 
        <input value={this.props.sbhlotto.jackpot2} onChange={(e) => this.props.setPropsState({ sbhlotto: { ...this.props.sbhlotto, jackpot2: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sbhlotto', 'jackpot2')}/>
      </div>
    </div>
  </div>
</Fragment>    
        )
    }
}
