const language = {
    en: {
      daysOfWeek: [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
      ],
    },
    cn: {
      daysOfWeek: [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ],
    },
  }
  
  exports.language = language
  