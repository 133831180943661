import React, {Component, Fragment} from 'react';
import '../../App.css';
import Select from 'react-select'
import { selectStyles, zodiacOption, characterList } from '../../static'

export default class totonum extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
<Fragment>
  <div>
  <div className="Tototittle">SportsToto 4D 多多</div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.toto.drawNo} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, drawNo: e.target.value} })}  className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', 'drawNo')}/>
    </div>
    <div style={{ display: 'flex',  color: 'white' }}>
      <div className="border"style={{ width: 'calc(100% / 3)',  fontWeight: 'bold' }}>1st Prize 首獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>2nd Prize 二獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>3rd Prize 三獎 </div>
    </div>
    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
      <div  style={{ width: 'calc(100% / 3)',  fontWeight: 'bold', display: 'flex' }}>
        <input value={this.props.toto.P1} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, P1: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', 'P1')}/>
        <input value={characterList[this.props.toto.P1OriPosition] || ''} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, P1OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'toto', 'P1OriPosition')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>
        <input value={this.props.toto.P2} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, P2: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', 'P2')}/>
        <input value={characterList[this.props.toto.P2OriPosition] || ''} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, P2OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'toto', 'P2OriPosition')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>
        <input value={this.props.toto.P3} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, P3: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', 'P3')}/>
        <input value={characterList[this.props.toto.P3OriPosition] || ''} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, P3OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'toto', 'P3OriPosition')}/>
      </div>
    </div>
    <div style={{ display: 'flex',  color: 'white', justifyContent: 'center' }}>
      <div className="border"style={{ width: '40%',  fontWeight: 'bold' }}>Zodiac</div>
    </div>
    <div style={{ display: 'flex',  color: 'white', justifyContent: 'center' }}>
      <Select 
        styles={selectStyles}
        options={zodiacOption} 
        value={this.props.toto['zodiac'] ? {value: this.props.toto['zodiac'], label: this.props.toto['zodiac']} : {}} 
        onChange={(e) => {
          this.props.setPropsState({ toto: { ...this.props.toto, zodiac: e.value}})
          this.props.selectEnter(e.value, 'toto', 'zodiac')
        }} 
        className="resulttop"
      />
    </div>
    <div className="border"style={{  fontWeight: 'bold',marginBottom:10 }}>Special 特別獎</div>
    <div style={{ display: 'flex',marginTop:3,  color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>A</span>
        <input value={this.props.toto['1']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 1: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '1')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>B</span>
        <input value={this.props.toto['2']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 2: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '2')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>C</span>
        <input value={this.props.toto['3']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 3: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '3')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>D</span>
        <input value={this.props.toto['4']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 4: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '4')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>E</span>
        <input value={this.props.toto['5']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 5: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '5')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>F</span>
        <input value={this.props.toto['6']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 6: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '6')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>G</span>
        <input value={this.props.toto['7']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 7: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '7')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>H</span>
        <input value={this.props.toto['8']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 8: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '8')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>I</span>
        <input value={this.props.toto['9']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 9: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '9')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>J</span>
        <input value={this.props.toto['10']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 10: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '10')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3,marginBottom:10, color: 'white' }}>
      <div className="" style={{width:'calc(100%/5)' , fontWeight:"bold"}}></div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>K</span>
        <input value={this.props.toto['11']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 11: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '11')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>L</span>
        <input value={this.props.toto['12']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 12: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '12')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>M</span>
        <input value={this.props.toto['13']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 13: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '13')}/>
      </div>
      <div className="" style={{width:'calc(100%/5)' , fontWeight:"bold"}}></div>
    </div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10}}>
                        Consolation 安慰獎
                        </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>N</span>
        <input value={this.props.toto['14']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 14: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '14')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>O</span>
        <input value={this.props.toto['15']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 15: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '15')}/>
      </div>
      <div   style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>P</span>
        <input value={this.props.toto['16']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 16: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '16')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>Q</span>
        <input value={this.props.toto['17']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 17: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '17')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>R</span>
        <input value={this.props.toto['18']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 18: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '18')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>S</span>
        <input value={this.props.toto['19']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 19: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '19')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>T</span>
        <input value={this.props.toto['20']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 20: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '20')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>U</span>
        <input value={this.props.toto['21']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 21: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '21')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>V</span>
        <input value={this.props.toto['22']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 22: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '22')}/>
      </div>
      <div style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>W</span>
        <input value={this.props.toto['23']} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, 23: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', '23')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div className="border" style={{width:'calc(100%/2)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>4D Jackpot 1 Prize</div>
      <div className="border" style={{width:'calc(100%/2)' , fontWeight:"bold"}}>4D Jackpot 2 Prize</div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  className="border3" style={{width:'calc(100%/2)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div>  
        <input value={this.props.toto.jackpot1} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, jackpot1: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', 'jackpot1')}/>
      </div>
      <div  className="border3" style={{width:'calc(100%/2)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div> 
        <input value={this.props.toto.jackpot2} onChange={(e) => this.props.setPropsState({ toto: { ...this.props.toto, jackpot2: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto', 'jackpot2')}/>
      </div>
    </div>
    <div className="Tototittle" style={{marginTop:10 , marginBottom:10}}>SUPREME TOTO 6/58</div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.totosup.drawNo} onChange={(e) => this.props.setPropsState({ totosup: { ...this.props.totosup, drawNo: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosup', 'drawNo')}/>
    </div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10}}>Winning Numbers</div>
    <div style={{ display: 'flex', marginTop:3,color: 'white' }}>
      <div style={{width:'calc(100%/6)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totosup['1']} onChange={(e) => this.props.setPropsState({ totosup: { ...this.props.totosup, 1: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosup', '1')}/>
      </div>
      <div style={{width:'calc(100%/6)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totosup['2']} onChange={(e) => this.props.setPropsState({ totosup: { ...this.props.totosup, 2: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosup', '2')}/>
      </div>
      <div style={{width:'calc(100%/6)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totosup['3']} onChange={(e) => this.props.setPropsState({ totosup: { ...this.props.totosup, 3: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosup', '3')}/>
      </div>
      <div style={{width:'calc(100%/6)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totosup['4']} onChange={(e) => this.props.setPropsState({ totosup: { ...this.props.totosup, 4: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosup', '4')}/>
      </div>
      <div style={{width:'calc(100%/6)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totosup['5']} onChange={(e) => this.props.setPropsState({ totosup: { ...this.props.totosup, 5: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosup', '5')}/>
      </div>
      <div style={{width:'calc(100%/6)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totosup['6']} onChange={(e) => this.props.setPropsState({ totosup: { ...this.props.totosup, 6: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosup', '6')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div className="border" style={{width:'calc(100%)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>Jackpot  Prize</div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white', marginBottom: 20 }}>
      <div  className="border3" style={{width:'calc(100%)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div>  
        <input value={this.props.totosup.jackpot1} onChange={(e) => this.props.setPropsState({ totosup: { ...this.props.totosup, jackpot1: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosup', 'jackpot1')}/>
      </div>
    </div>
    <div className="Tototittle" style={{marginTop:10 , marginBottom:10}}>POWER TOTO 6/55</div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.totopow.drawNo} onChange={(e) => this.props.setPropsState({ totopow: { ...this.props.totopow, drawNo: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totopow', 'drawNo')}/>
    </div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10}}>Winning Numbers</div>
    <div style={{ display: 'flex', marginTop:3,color: 'white' }}>
      <div style={{width:'calc(100%/6)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totopow['1']} onChange={(e) => this.props.setPropsState({ totopow: { ...this.props.totopow, 1: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totopow', '1')}/>
      </div>
      <div style={{width:'calc(100%/6)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totopow['2']} onChange={(e) => this.props.setPropsState({ totopow: { ...this.props.totopow, 2: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totopow', '2')}/>
      </div>
      <div style={{width:'calc(100%/6)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totopow['3']} onChange={(e) => this.props.setPropsState({ totopow: { ...this.props.totopow, 3: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totopow', '3')}/>
      </div>
      <div style={{width:'calc(100%/6)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totopow['4']} onChange={(e) => this.props.setPropsState({ totopow: { ...this.props.totopow, 4: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totopow', '4')}/>
      </div>
      <div style={{width:'calc(100%/6)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totopow['5']} onChange={(e) => this.props.setPropsState({ totopow: { ...this.props.totopow, 5: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totopow', '5')}/>
      </div>
      <div style={{width:'calc(100%/6)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totopow['6']} onChange={(e) => this.props.setPropsState({ totopow: { ...this.props.totopow, 6: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totopow', '6')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div className="border" style={{width:'calc(100%)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>Jackpot Prize</div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white', marginBottom: 20 }}>
      <div  className="border3" style={{width:'calc(100%)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div>  
        <input value={this.props.totopow.jackpot1} onChange={(e) => this.props.setPropsState({ totopow: { ...this.props.totopow, jackpot1: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totopow', 'jackpot1')}/>
      </div>
    </div>
    <div className="Tototittle" style={{marginTop:10 , marginBottom:10}}>STAR TOTO 6/50</div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.totosta.drawNo} onChange={(e) => this.props.setPropsState({ totosta: { ...this.props.totosta, drawNo: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosta', 'drawNo')}/>
    </div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Winning Number</div>
    <div style={{ display: 'flex', marginTop:3,color: 'white' }}>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totosta['1']} onChange={(e) => this.props.setPropsState({ totosta: { ...this.props.totosta, 1: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosta', '1')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totosta['2']} onChange={(e) => this.props.setPropsState({ totosta: { ...this.props.totosta, 2: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosta', '2')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totosta['3']} onChange={(e) => this.props.setPropsState({ totosta: { ...this.props.totosta, 3: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosta', '3')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totosta['4']} onChange={(e) => this.props.setPropsState({ totosta: { ...this.props.totosta, 4: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosta', '4')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totosta['5']} onChange={(e) => this.props.setPropsState({ totosta: { ...this.props.totosta, 5: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosta', '5')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totosta['6']} onChange={(e) => this.props.setPropsState({ totosta: { ...this.props.totosta, 6: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosta', '6')}/>
      </div>
      <div className="plusbtn" style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>+</div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.totosta['8']} onChange={(e) => this.props.setPropsState({ totosta: { ...this.props.totosta, 8: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosta', '8')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div className="border" style={{width:'calc(100%/2)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>Jackpot 1 Prize</div>
      <div className="border" style={{width:'calc(100%/2)' , fontWeight:"bold"}}>Jackpot 2 Prize</div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white', marginBottom: 20 }}>
      <div  className="border3" style={{width:'calc(100%/2)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div>  
        <input value={this.props.totosta.jackpot1} onChange={(e) => this.props.setPropsState({ totosta: { ...this.props.totosta, jackpot1: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosta', 'jackpot1')}/>
      </div>
      <div  className="border3" style={{width:'calc(100%/2)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div> 
        <input value={this.props.totosta.jackpot2} onChange={(e) => this.props.setPropsState({ totosta: { ...this.props.totosta, jackpot2: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'totosta', 'jackpot2')}/>
      </div>
    </div>
    <div className="Tototittle">SportsToto 5D</div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.toto5.drawNo} onChange={(e) => this.props.setPropsState({ toto5: { ...this.props.toto5, drawNo: e.target.value} })}  className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto5', 'drawNo')}/>
    </div>
    <div style={{ display: 'flex',  color: 'white' }}>
      <div className="border"style={{ width: 'calc(100% / 3)',  fontWeight: 'bold' }}>1st Prize 首獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>2nd Prize 二獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>3rd Prize 三獎 </div>
    </div>
    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
      <div  style={{ width: 'calc(100% / 3)',  fontWeight: 'bold', display: 'flex' }}>
        <input value={this.props.toto5['1']} onChange={(e) => this.props.setPropsState({ toto5: { ...this.props.toto5, ['1']: e.target.value} })} className="resulttop" style={{width:'100%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto5', '1')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>
        <input value={this.props.toto5['2']} onChange={(e) => this.props.setPropsState({ toto5: { ...this.props.toto5, ['2']: e.target.value} })} className="resulttop" style={{width:'100%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto5', '2')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>
        <input value={this.props.toto5['3']} onChange={(e) => this.props.setPropsState({ toto5: { ...this.props.toto5, ['3']: e.target.value} })} className="resulttop" style={{width:'100%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto5', '3')}/>
      </div>
    </div>
    <div className="Tototittle">SportsToto 6D</div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.toto6.drawNo} onChange={(e) => this.props.setPropsState({ toto6: { ...this.props.toto6, drawNo: e.target.value} })}  className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto6', 'drawNo')}/>
    </div>
    <div style={{ display: 'flex',  color: 'white' }}>
      <div className="border"style={{ width: 'calc(100%)',  fontWeight: 'bold' }}>1st Prize 首獎</div>
    </div>
    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
      <div  style={{ width: 'calc(100%)',  fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
        <input value={this.props.toto6['1']} onChange={(e) => this.props.setPropsState({ toto6: { ...this.props.toto6, ['1']: e.target.value} })} className="resulttop" style={{width:'50%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'toto6', '1')}/>
      </div>
    </div>
  </div>
</Fragment>    
        )
    }
}
