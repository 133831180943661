export const characterList = {
    1: 'A',
    2: 'B',
    3: 'C',
    4: 'D',
    5: 'E',
    6: 'F',
    7: 'G',
    8: 'H',
    9: 'I',
    10: 'J',
    11: 'K',
    12: 'L',
    13: 'M',
    14: 'N',
    15: 'O',
    16: 'P',
    17: 'Q',
    18: 'R',
    19: 'S',
    20: 'T',
    21: 'U',
    22: 'V',
    23: 'W',
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    G: 'G',
    H: 'H',
    I: 'I',
    J: 'J',
    K: 'K',
    L: 'L',
    M: 'M',
    N: 'N',
    O: 'O',
    P: 'P',
    Q: 'Q',
    R: 'R',
    S: 'S',
    T: 'T',
    U: 'U',
    V: 'V',
    W: 'W',
}

export const characterList2 = {
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
  I: 9,
  J: 10,
  K: 11,
  L: 12,
  M: 13,
}
export const initialObject = {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: '',
    13: '',
    14: '',
    15: '',
    16: '',
    17: '',
    18: '',
    19: '',
    20: '',
    21: '',
    22: '',
    23: '',
    24: '',
    25: '',
    26: '',
    P1: '',
    P2: '',
    P3: '',
    '5D1': '',
    '6D1': '',
    '7D1': '',
    P1OriPosition: '',
    P2OriPosition: '',
    P3OriPosition: '',
    drawNo: '',
    jackpot1: '',
    jackpot2: '',
    jackpot3: '',
}

export const selectStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '40%',
      backgroundColor: 'red',
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'red',
      color: 'white',
      minHeight: 42,
      height: 42,
      border: 'none',
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: 'white',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 42,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: 20,
    }),
}

export const zodiacOption = [
    { label: 'EMPTY', value: '' },
    { label: 'PIG', value: 'PIG' },
    { label: 'DOG', value: 'DOG' },
    { label: 'DRAGON', value: 'DRAGON' },
    { label: 'GOAT', value: 'GOAT' },
    { label: 'HORSE', value: 'HORSE' },
    { label: 'MONKEY', value: 'MONKEY' },
    { label: 'OX', value: 'OX' },
    { label: 'RABBIT', value: 'RABBIT' },
    { label: 'RAT', value: 'RAT' },
    { label: 'ROOSTER', value: 'ROOSTER' },
    { label: 'SNAKE', value: 'SNAKE' },
    { label: 'TIGER', value: 'TIGER' },
]