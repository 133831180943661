const initialState = {
    currentLanguage: 'cn',
    token: '',
  }
  
  function reducer(state = initialState, action) {
    switch (action.type) {
    case 'SET_LANGUAGE':
      // Language
      localStorage.setItem('currentLanguage', action.payload)
      return {
        ...state,
        currentLanguage: action.payload,
      };
      // Login Details
    case 'LOGIN_STATUS':
      return {
        ...state,
        token: action.payload.token,
      };
    default:
      return state;
    }
  }
  
  export default reducer
  