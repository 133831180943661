import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
// import { IoIosArrowDropdownCircle } from '@react-icons/all-files/io/IoIosArrowDropdownCircle';
// import { connect } from 'react-redux'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserRole: this.props.Role,
      Username: this.props.username,
      Authority: {},
    }
  }

  render() {
    return (
      <div style={{ paddingBottom: 80 }}>
        <Navbar bg="light" expand="lg" fixed='top'>
          <Container style={{ justifyContent: 'space-between'}}>
            <Navbar.Brand href="#home">Admin Dashboard</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/Home">首页</Nav.Link>
                {/* <Nav.Link href="/">登出</Nav.Link> */}
                <Button style={{ marginLeft: 10 }} variant="outline-success" onClick={() => {
                  localStorage.removeItem('username');
                  localStorage.removeItem('token');
                  localStorage.removeItem('currentLanguage');
                  window.location.href = '/';
                }}>登出</Button>
                {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

// function mapStateToProps(state, ownProps) {
//   return {
//     username: state.username,
//     token: state.token,
//     Role: state.Role,
//     currentLanguage: state.currentLanguage,
//     isSub: state.isSub,
//     UserAuthority: state.UserAuthority,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     setBooleanReturn: (Boolean) => {
//       dispatch({
//         type: 'BACK_URL',
//         payload: {
//           BooleanBack: Boolean,
//         },
//       });
//     },
//   };
// }

export default Header;