import React, {Component, Fragment} from 'react';
import '../../App.css';
import { characterList } from '../../static'

export default class Magnum extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
<Fragment>
  <div>
  <div className="Magnumtittle">Magnum 4D 萬能</div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.mag.drawNo} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, drawNo: e.target.value} })}  className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', 'drawNo')}/>
    </div>
    <div style={{ display: 'flex',  color: 'white' }}>
      <div className="border"style={{ width: 'calc(100% / 3)',  fontWeight: 'bold' }}>1st Prize 首獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>2nd Prize 二獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>3rd Prize 三獎 </div>
    </div>
    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
      <div  style={{ width: 'calc(100% / 3)',  fontWeight: 'bold', display: 'flex' }}>
        <input value={this.props.mag.P1} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, P1: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', 'P1')}/>
        <input value={characterList[this.props.mag.P1OriPosition] || ''} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, P1OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'mag', 'P1OriPosition')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>
        <input value={this.props.mag.P2} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, P2: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', 'P2')}/>
        <input value={characterList[this.props.mag.P2OriPosition] || ''} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, P2OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'mag', 'P2OriPosition')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>
        <input value={this.props.mag.P3} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, P3: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', 'P3')}/>
        <input value={characterList[this.props.mag.P3OriPosition] || ''} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, P3OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'mag', 'P3OriPosition')}/>
      </div>
    </div>
    <div className="border"style={{  fontWeight: 'bold',marginBottom:10 }}>Special 特別獎</div>
    <div style={{ display: 'flex',marginTop:3,  color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>A</span>
        <input value={this.props.mag['1']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 1: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '1')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>B</span>
        <input value={this.props.mag['2']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 2: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '2')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>C</span>
        <input value={this.props.mag['3']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 3: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '3')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>D</span>
        <input value={this.props.mag['4']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 4: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '4')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>E</span>
        <input value={this.props.mag['5']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 5: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '5')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>F</span>
        <input value={this.props.mag['6']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 6: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '6')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>G</span>
        <input value={this.props.mag['7']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 7: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '7')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>H</span>
        <input value={this.props.mag['8']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 8: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '8')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>I</span>
        <input value={this.props.mag['9']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 9: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '9')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>J</span>
        <input value={this.props.mag['10']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 10: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '10')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3,marginBottom:10, color: 'white' }}>
      <div className="" style={{width:'calc(100%/5)' , fontWeight:"bold"}}></div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>K</span>
        <input value={this.props.mag['11']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 11: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '11')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>L</span>
        <input value={this.props.mag['12']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 12: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '12')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>M</span>
        <input value={this.props.mag['13']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 13: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '13')}/>
      </div>
      <div className="" style={{width:'calc(100%/5)' , fontWeight:"bold"}}></div>
    </div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10}}>
                        Consolation 安慰獎
                        </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>N</span>
        <input value={this.props.mag['14']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 14: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '14')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>O</span>
        <input value={this.props.mag['15']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 15: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '15')}/>
      </div>
      <div   style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>P</span>
        <input value={this.props.mag['16']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 16: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '16')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>Q</span>
        <input value={this.props.mag['17']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 17: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '17')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>R</span>
        <input value={this.props.mag['18']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 18: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '18')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>S</span>
        <input value={this.props.mag['19']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 19: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '19')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>T</span>
        <input value={this.props.mag['20']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 20: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '20')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>U</span>
        <input value={this.props.mag['21']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 21: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '21')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>V</span>
        <input value={this.props.mag['22']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 22: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '22')}/>
      </div>
      <div style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>W</span>
        <input value={this.props.mag['23']} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, 23: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', '23')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div className="border" style={{width:'calc(100%/2)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>4D Jackpot 1 Prize</div>
      <div className="border" style={{width:'calc(100%/2)' , fontWeight:"bold"}}>4D Jackpot 2 Prize</div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  className="border3" style={{width:'calc(100%/2)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div>  
        <input value={this.props.mag.jackpot1} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, jackpot1: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', 'jackpot1')}/>
      </div>
      <div  className="border3" style={{width:'calc(100%/2)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div> 
        <input value={this.props.mag.jackpot2} onChange={(e) => this.props.setPropsState({ mag: { ...this.props.mag, jackpot2: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'mag', 'jackpot2')}/>
      </div>
    </div>
    <div className="Magnumtittle" style={{marginTop:10 , marginBottom:10}}>Magnum Life 万能天天彩</div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.magl.drawNo} onChange={(e) => this.props.setPropsState({ magl: { ...this.props.magl, drawNo: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magl', 'drawNo')}/>
    </div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10}}>Winning Numbers</div>
    <div style={{ display: 'flex', marginTop:3,color: 'white' }}>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magl['1']} onChange={(e) => this.props.setPropsState({ magl: { ...this.props.magl, 1: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magl', '1')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magl['2']} onChange={(e) => this.props.setPropsState({ magl: { ...this.props.magl, 2: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magl', '2')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magl['3']} onChange={(e) => this.props.setPropsState({ magl: { ...this.props.magl, 3: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magl', '3')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magl['4']} onChange={(e) => this.props.setPropsState({ magl: { ...this.props.magl, 4: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magl', '4')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magl['5']} onChange={(e) => this.props.setPropsState({ magl: { ...this.props.magl, 5: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magl', '5')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magl['6']} onChange={(e) => this.props.setPropsState({ magl: { ...this.props.magl, 6: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magl', '6')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magl['7']} onChange={(e) => this.props.setPropsState({ magl: { ...this.props.magl, 7: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magl', '7')}/>
      </div>
      <div style={{width:'calc(100%/8)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magl['8']} onChange={(e) => this.props.setPropsState({ magl: { ...this.props.magl, 8: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magl', '8')}/>
      </div>
    </div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Bonus Numbers</div>
    <div style={{display:'flex',marginTop:3, color:'white'}}>
      <div style={{width:'calc(100%/2)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magl['9']} onChange={(e) => this.props.setPropsState({ magl: { ...this.props.magl, 9: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magl', '9')}/>
      </div>
      <div style={{width:'calc(100%/2)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magl['10']} onChange={(e) => this.props.setPropsState({ magl: { ...this.props.magl, 10: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magl', '10')}/>
      </div>
    </div>
    <div className="Magnumtittle" style={{marginTop:10 , marginBottom:10}}>Magnum 4D Jackpot Gold 萬能黃金万字积宝</div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.magjg.drawNo} onChange={(e) => this.props.setPropsState({ magjg: { ...this.props.magjg, drawNo: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magjg', 'drawNo')}/>
    </div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Winning Number</div>
    <div style={{ display: 'flex', marginTop:3,color: 'white' }}>
      <div style={{width:'calc(100%/9)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magjg['1']} onChange={(e) => this.props.setPropsState({ magjg: { ...this.props.magjg, 1: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magjg', '1')}/>
      </div>
      <div style={{width:'calc(100%/9)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magjg['2']} onChange={(e) => this.props.setPropsState({ magjg: { ...this.props.magjg, 2: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magjg', '2')}/>
      </div>
      <div style={{width:'calc(100%/9)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magjg['3']} onChange={(e) => this.props.setPropsState({ magjg: { ...this.props.magjg, 3: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magjg', '3')}/>
      </div>
      <div style={{width:'calc(100%/9)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magjg['4']} onChange={(e) => this.props.setPropsState({ magjg: { ...this.props.magjg, 4: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magjg', '4')}/>
      </div>
      <div style={{width:'calc(100%/9)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magjg['5']} onChange={(e) => this.props.setPropsState({ magjg: { ...this.props.magjg, 5: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magjg', '5')}/>
      </div>
      <div style={{width:'calc(100%/9)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magjg['6']} onChange={(e) => this.props.setPropsState({ magjg: { ...this.props.magjg, 6: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magjg', '6')}/>
      </div>
      <div className="plusbtn" style={{width:'calc(100%/9)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>+</div>
      <div style={{width:'calc(100%/9)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magjg['7']} onChange={(e) => this.props.setPropsState({ magjg: { ...this.props.magjg, 7: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magjg', '7')}/>
      </div>
      <div style={{width:'calc(100%/9)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>
        <input value={this.props.magjg['8']} onChange={(e) => this.props.setPropsState({ magjg: { ...this.props.magjg, 8: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magjg', '8')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div className="border" style={{width:'calc(100%/2)' , marginLeft: 4, marginRight: 4,fontWeight:"bold"}}>Jackpot 1 Prize</div>
      <div className="border" style={{width:'calc(100%/2)' , fontWeight:"bold"}}>Jackpot 2 Prize</div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white', marginBottom: 20 }}>
      <div  className="border3" style={{width:'calc(100%/2)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div>  
        <input value={this.props.magjg.jackpot1} onChange={(e) => this.props.setPropsState({ magjg: { ...this.props.magjg, jackpot1: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magjg', 'jackpot1')}/>
      </div>
      <div  className="border3" style={{width:'calc(100%/2)' ,fontWeight:"bold", display: 'flex', justifyContent: 'center'}}>
        <div style={{ marginRight: 10 }}>RM</div> 
        <input value={this.props.magjg.jackpot2} onChange={(e) => this.props.setPropsState({ magjg: { ...this.props.magjg, jackpot2: e.target.value} })}className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'magjg', 'jackpot2')}/>
      </div>
    </div>
  </div>
</Fragment>    
        )
    }
}
