import React, {Component, Fragment} from 'react';
import '../../App.css';
import { characterList } from '../../static'

export default class sdknum extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
<Fragment>
  <div>
  <div className="Stctittle">Sandakan 4D 山打根赛马会</div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10,marginTop:10 }}>Draw No</div>
    <div className="border3" style={{ marginTop:10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
      <input value={this.props.sdk.drawNo} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, drawNo: e.target.value} })}  className="border3" style={{width:'calc(100% /2)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', 'drawNo')}/>
    </div>
    <div style={{ display: 'flex',  color: 'white' }}>
      <div className="border"style={{ width: 'calc(100% / 3)',  fontWeight: 'bold' }}>1st Prize 首獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>2nd Prize 二獎</div>
      <div className="border"style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>3rd Prize 三獎 </div>
    </div>
    <div style={{ display: 'flex', marginTop:3 ,marginBottom:10, color: 'white' }}>
      <div  style={{ width: 'calc(100% / 3)',  fontWeight: 'bold', display: 'flex' }}>
        <input value={this.props.sdk.P1} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, P1: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', 'P1')}/>
        <input value={characterList[this.props.sdk.P1OriPosition] || ''} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, P1OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'sdk', 'P1OriPosition')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', marginLeft: 4, marginRight: 4, fontWeight: 'bold' }}>
        <input value={this.props.sdk.P2} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, P2: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', 'P2')}/>
        <input value={characterList[this.props.sdk.P2OriPosition] || ''} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, P2OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'sdk', 'P2OriPosition')}/>
      </div>
      <div  style={{ width: 'calc(100% / 3)', fontWeight: 'bold' }}>
        <input value={this.props.sdk.P3} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, P3: e.target.value} })} className="resulttop" style={{width:'80%'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', 'P3')}/>
        <input value={characterList[this.props.sdk.P3OriPosition] || ''} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, P3OriPosition: characterList[e.target.value]} })} className="resulttop" style={{width:'20%'}} type="text" onKeyPress={(e) => this.props.onKeyEnterOriPosition(e, 'sdk', 'P3OriPosition')}/>
      </div>
    </div>
    <div className="border"style={{  fontWeight: 'bold',marginBottom:10 }}>Special 特別獎</div>
    <div style={{ display: 'flex',marginTop:3,  color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>A</span>
        <input value={this.props.sdk['1']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 1: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '1')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>B</span>
        <input value={this.props.sdk['2']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 2: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '2')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>C</span>
        <input value={this.props.sdk['3']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 3: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '3')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>D</span>
        <input value={this.props.sdk['4']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 4: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '4')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>E</span>
        <input value={this.props.sdk['5']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 5: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '5')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>F</span>
        <input value={this.props.sdk['6']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 6: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '6')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>G</span>
        <input value={this.props.sdk['7']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 7: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '7')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>H</span>
        <input value={this.props.sdk['8']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 8: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '8')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>I</span>
        <input value={this.props.sdk['9']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 9: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '9')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>J</span>
        <input value={this.props.sdk['10']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 10: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '10')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3,marginBottom:10, color: 'white' }}>
      <div className="" style={{width:'calc(100%/5)' , fontWeight:"bold"}}></div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>K</span>
        <input value={this.props.sdk['11']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 11: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '11')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>L</span>
        <input value={this.props.sdk['12']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 12: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '12')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>M</span>
        <input value={this.props.sdk['13']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 13: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '13')}/>
      </div>
      <div className="" style={{width:'calc(100%/5)' , fontWeight:"bold"}}></div>
    </div>
    <div className="border"style={{  fontWeight: 'bold' ,marginBottom:10}}>
                        Consolation 安慰獎
                        </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>N</span>
        <input value={this.props.sdk['14']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 14: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '14')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>O</span>
        <input value={this.props.sdk['15']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 15: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '15')}/>
      </div>
      <div   style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>P</span>
        <input value={this.props.sdk['16']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 16: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '16')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>Q</span>
        <input value={this.props.sdk['17']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 17: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '17')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>R</span>
        <input value={this.props.sdk['18']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 18: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '18')}/>
      </div>
    </div>
    <div style={{ display: 'flex', marginTop:3, color: 'white' }}>
      <div  style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>S</span>
        <input value={this.props.sdk['19']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 19: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '19')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>T</span>
        <input value={this.props.sdk['20']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 20: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '20')}/>
      </div>
      <div  style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4, fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>U</span>
        <input value={this.props.sdk['21']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 21: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '21')}/>
      </div>
      <div style={{width:'calc(100%/5)' , marginLeft: 4, marginRight: 4,fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>V</span>
        <input value={this.props.sdk['22']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 22: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '22')}/>
      </div>
      <div style={{width:'calc(100%/5)' , fontWeight:"bold", position: "relative"}}>
        <span style={{ color: 'red', position: 'absolute', top: 0, left: 3, fontSize: '20px' }}>W</span>
        <input value={this.props.sdk['23']} onChange={(e) => this.props.setPropsState({ sdk: { ...this.props.sdk, 23: e.target.value} })}className="resultbottom" style={{width:'calc(100%)'}} type="text" onKeyPress={(e) => this.props.onKeyEnter(e, 'sdk', '23')}/>
      </div>
    </div>
  </div>
</Fragment>    
        )
    }
}
