// import useToken from './Component/useToken';
// const base = process.env.REACT_APP_SERVER
const base = 'https://server.ace4dv2.live/';
// const base = 'http://localhost:8003/';

export function getDataByDate(date) {
    return new Promise((resolve) => {
        fetch(`${base}result4D/byDate`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ date }),
        }).then((res) => {
            if (res.status === 429) {
            resolve({ error: 'type2'})
            } else if (res.status !== 200) {
            resolve({})
            } else {
            resolve(res.json())
            }
        }).catch(rejected => {
            console.log(rejected);
        });
    })
}

export function get5DDataByDate(date) {
    return new Promise((resolve) => {
        fetch(`${base}result5D6D/byDate`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ date }),
        }).then((res) => {
            if (res.status === 429) {
            resolve({ error: 'type2'})
            } else if (res.status !== 200) {
            resolve({})
            } else {
            resolve(res.json())
            }
        }).catch(rejected => {
            console.log(rejected);
        });
    })
}

export function loginUser(credentials) {
    return new Promise((resolve) => {
        fetch(`${base}publicAPI/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
        .then((res) => {
            resolve(res.json())
        }).catch(rejected => {
            console.log(rejected);
        });
    })
}

export function updateNumber(obj, token) {
    return new Promise((resolve) => {
        fetch(`${base}dashEntry/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: token,
                ...obj,
            })
        })
        .then((res) => {
            resolve(res.json())
        }).catch(rejected => {
            console.log(rejected);
        });
    })
}

export function validateToken(obj, token) {
    return new Promise((resolve) => {
        fetch(`${base}dashEntry/validateToken`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: token,
            })
        })
        .then((res) => {
            resolve(res.json())
        }).catch(rejected => {
            console.log(rejected);
        });
    })
}

// export function getRecord(date, token) {
//     return new Promise((resolve) => {
//         fetch(`${base}dashEntry/record`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({
//                 date,
//                 token: token,
//             })
//         })
//         .then((res) => {
//             resolve(res.json())
//         }).catch(rejected => {
//             console.log(rejected);
//         });
//     })
// }
