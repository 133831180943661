import React from 'react';
import { GoSignIn } from '@react-icons/all-files/go/GoSignIn';
import { FaEye } from '@react-icons/all-files/fa/FaEye';
import { FaEyeSlash } from '@react-icons/all-files/fa/FaEyeSlash';
import Loading3 from '../Images/loading2.gif';
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect';
import SuccessLogo from '../Images/errorsign.gif';
import { loginUser } from '../api.js'
var timer

class Login extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        ShowPassword: false,
        MessageType: '',
        DisplayErrorMessage: '',
        DisplayError: false,
      }
    }
  
    async componentDidMount() {
    }

    async handleSubmit() {
      const Password = this.state.Password
      const Username = this.state.Username
      const token = await loginUser({
        username: Username,
        password: Password,
      });
      console.log('token', token)
      localStorage.setItem("token", token.token);
      this.props.setLoginStatus(token.token);
      if(token.error === 'Invalid Username and Password') {
        this.DisplayMEssage('用户名或密码出现错误', 'Error')
        //ignore
      } else {
        this.props.history.push('/Home')
      }
    }

    onKeyEnter(e) {
      if (e.charCode === 13) {
        this.handleSubmit(e);
      }
    }

    handleChange(event, type) {
      this.setState({ [type]: event })
    }

    DisplayMEssage(Message, type) {
      this.setState({ DisplayErrorMessage: Message, DisplayError: true, MessageType: type })
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.setState({ 
          DisplayError: false,
          DisplayErrorMessage: '',
          MessageType: '',
        });
      }, 5000);
    }

    render() {
      return (
        <div className="dcc backgroundImageLogin" style={{ width: '100%', height: '100vh' }}>
          {this.state.loading ? (
            <div style={{ flexDirection: 'column', width: '100%', marginTop: -150 }}>
              <div>
                <img src={Loading3} alt="Logo" className="logoimages" style={{ marginBottom: 30, marginTop: 50 }} />
                <div className="loadingMessage" style={{ color: 'white' }}> Loading ... Please Wait a moment</div>
              </div>
            </div>
          ) : (
            <div className="dcc" style={{ width: '100%' }}>
              <div style={{ width: '70%', border: `1px solid black`, flexDirection: 'column'}}>
                <div className='dcc' style={{ width: '100%', height: 'auto', fontWeight: 'bold', color: 'white', backgroundColor: 'black', fontSize: '30px', padding: 35 }}>
                  Admin Dashboard
                  {/* <img src={MoGuHeader} alt="Logo" style={{ width: '100%', height: (isMobile) ? 80 : 'auto'}}/> */}
                </div>
                {this.state.DisplayError === true && 
                <div className='dcc' style={{ marginBottom: 0, display: 'flex', flexDirection: 'row', backgroundColor: 'white' }}>
                  <img src={SuccessLogo} alt="Logo" style={{ width: 60, marginRight: 5 }}/>
                 <div style={{ fontWeight: 'bold', fontSize: '20px', color: (this.state.MessageType === 'Error') ? 'red' : '#77B43F'}}>{` ${this.state.DisplayErrorMessage}`}</div>
                </div>}
                <div className="dcc" style={{ backgroundColor: '#343A40', width: '100%', flexDirection: 'column'}}>
                  <div className="dcc" style={{ width: '100%', height: 'auto', marginBottom: 25, paddingTop: '8%', flexDirection: (isMobile) ? 'column' : 'row' }}>
                    <div style={{ width: (isMobile) ? '20%' : '10%', color: 'white', fontWeight: 'bold' }}>用户名</div>
                    <div className='dcc' style={{ width: (isMobile) ? '80%': 'auto', border: `2px solid lightblue`}}>
                      <div style={{ width: (isMobile) ? '80%' : 480, backgroundColor: 'white', height: 30, textAlign: 'left' }}>
                        <input
                          className="LoginInputText"
                          type="text"
                          style={{ border: 'none', width: '90%' }}
                          value={this.state.Username}
                          onChange={(e) => this.handleChange(e.target.value, 'Username')}
                          ref={(input) => { this.UsernameInput = input; }}
                          placeholder={'用户名'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="dcc" style={{ width: '100%', height: 'auto', flexDirection: (isMobile) ? 'column' : 'row'}}>
                    <div style={{ width: (isMobile) ? '20%' : '10%', color: 'white', fontWeight: 'bold' }}>密码</div>
                    <div className='dcc' style={{ width: (isMobile) ? '80%': 'auto', border: `2px solid lightblue`}}>
                      <div style={{ width: (isMobile) ? '80%' : 480, backgroundColor: 'white', textAlign: 'left', flexDirection: 'row' }}>
                        <input
                          className="LoginInputText"
                          style={{ border: 'none', width: '90%' }}
                          type={(this.state.ShowPassword === true) ? "Text" : "Password"}
                          value={this.state.Password}
                          onChange={(e) => this.handleChange(e.target.value, 'Password')}
                          placeholder={'密码'}
                          ref={(input) => { this.PasswordInput = input; }}
                          onKeyPress={(e) => this.onKeyEnter(e)}
                        />
                        {this.state.ShowPassword === false
                          ? <FaEyeSlash style={{ textAlign: 'center', width: '10%' }} size="25px" color="gray" onClick={()=> this.setState({ShowPassword: true })}/>
                          : <FaEye style={{ textAlign: 'center', width: '10%' }} size="25px" color="gray" onClick={()=> this.setState({ShowPassword: false })}/> 
                        }
                      </div>
                    </div>
                  </div>
                  <div className='dcc' style={{ width: '100%', paddingBottom: '8%' }}>
                    <button className="ButtonLogin" style={{ flexDirection: 'row' }} id="searchButton" onClick={()=> this.handleSubmit()}>
                      <p1 style={{ fontSize: '18px', fontWeight: 'bold', marginRight: 5, color: 'white' }}>
                    登入
                      </p1>
                      <GoSignIn style={{ textAlign: 'center' }} size="25px" color="lightblue" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  }

  
  function mapDispatchToProps(dispatch) {
    return {
      setLoginStatus: (UserToken) => {
        dispatch({
          type: 'LOGIN_STATUS',
          payload: {
            token: UserToken,
          },
        });
      },
    };
  }
  
  export default connect(null, mapDispatchToProps)(Login);